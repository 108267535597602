.input-datepicker .ant-picker-panel-container {
  border-radius: 10px;
  box-shadow: 0 0 0 2px rgb(48 117 255 / 10%);
  margin-top: 5px;
}

.input-datepicker .ant-picker-panel {
  border: 0;
}

.input-datepicker .ant-picker-body {
  padding: 12px;
}

.input-datepicker .ant-picker-header,
.input-datepicker .ant-picker-footer {
  background-color: var(--white);
}

.input-datepicker .ant-picker-footer a {
  color: var(--primary) !important;
}

.input-datepicker
  .ant-picker-body
  .ant-picker-content
  .ant-picker-cell-today
  .ant-picker-cell-inner {
  color: var(--primary) !important;
}

.input-datepicker
  .ant-picker-body
  .ant-picker-content
  .ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: transparent;
}

.input-datepicker
  .ant-picker-body
  .ant-picker-content
  .ant-picker-cell-selected {
  background-color: var(--primary);
  border-radius: 8px;
}

.input-datepicker
  .ant-picker-body
  .ant-picker-content
  .ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: transparent;
}

.input-datepicker .ant-picker-header-super-prev-btn,
.input-datepicker .ant-picker-header-prev-btn,
.input-datepicker .ant-picker-header-view,
.input-datepicker .ant-picker-header-next-btn,
.input-datepicker .ant-picker-header-super-next-btn {
  color: var(--text);
}

.input-datepicker .ant-picker-header-super-prev-btn:hover,
.input-datepicker .ant-picker-header-prev-btn:hover,
.input-datepicker .ant-picker-header-view button:hover,
.input-datepicker .ant-picker-header-next-btn:hover,
.input-datepicker .ant-picker-header-super-next-btn:hover {
  color: var(--primary);
}

.input-datepicker .ant-picker-cell:not(.ant-picker-cell-selected):hover {
  background-color: var(--input-background) !important;
  border-radius: 8px;
  color: var(--primary);
}

.input-datepicker .ant-picker-cell:hover .ant-picker-cell-inner {
  background-color: transparent !important;
}
