@font-face {
  font-family: 'Manrope';
  src: local('Manrope'),
    url('./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'ManropeBold';
  src: local('ManropeBold'),
    url('./assets/fonts/Manrope/static/Manrope-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ManropeExtraBold';
  src: local('ManropeExtraBold'),
    url('./assets/fonts/Manrope/static/Manrope-ExtraBold.ttf')
      format('truetype');
}
