@import 'fonts.css';
@import './assets/stylesheets/antd_datepicker.css';
@import './assets/stylesheets/antd_select_popup.css';

:root {
  --primary: #3075ff;
  --input-background: #f5f8ff;
  --text: #050c19;
  --white: #ffffff;
}

body {
  font-family: 'Manrope', serif !important;
}

.ant-select-item-group {
  font-size: 16px !important;
  padding-left: 8px !important;
}
