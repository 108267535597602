.select-popup {
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgb(48 117 255 / 10%);
  margin-top: 5px;
}

.select-popup .rc-virtual-list {
  padding: 0 5px;
}

.select-popup .ant-select-item.ant-select-item-option {
  padding: 8px;
  border-radius: 4px;
  color: var(--text);
}

.select-popup
  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: var(--input-background);
  color: var(--primary);
}
